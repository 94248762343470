<template>
    <div class="animation">
        <div v-if="hitokoto.hitokoto" class="hitokoto">
            <div>{{hitokoto.hitokoto}}</div>
            <p class="hitokoto-from">{{'————《'+hitokoto.from+'》'}}</p>
        </div>
        <el-aside class="sidebar" style="width: unset">

            <ul style="list-style: none">
                <li class="sidebar-item" v-for="item in categoryList" :key="item.id" @click="setCate(item.id)">
                    <span>{{item.name}}</span>
                    <div v-if="queryInfo.categoryId===item.id" class="line"></div>
                </li>
            </ul>
        </el-aside>
        <el-main class="wrap">
            <div class="top_title">
                <span style="font-size: 1.8em;color: #000000;user-select: none;">影视列表</span>
                <br/>
                <span class="title-cate" @click="reset">{{tvCate[0]?tvCate[0].name:''}}</span>
            </div>
            <div id="main">
                <el-radio-group v-model="queryInfo.watch" size="mini" @change="getData">
                    <el-radio-button label="0">正在追</el-radio-button>
                    <el-radio-button label="1">还未追</el-radio-button>
                    <el-radio-button label="2">已追完</el-radio-button>
                </el-radio-group>
                <br>
                <div class="type_list" v-for="item in typeList" :key="item.id" @click="setType(item.id)">
                    <span class="type_li" :style="queryInfo.type===item.id?'background-color:#23aaff;':''">{{item.type_name}}</span>
                </div>
                <div class="content">
                    <div v-for="item in tvList" :key="item.id" class="content_box">
                        <a :href="item.url" style="text-decoration: none">
                            <img :src="item.img" alt="" class="content_box_img">
                            <div class="content_box_title">{{item.name}}</div>
                        </a>
                    </div>
                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="queryInfo.pageNum"
                               :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize" :total="total"
                               layout="total, sizes, prev, pager, next, jumper" background>
                </el-pagination>
            </div>
        </el-main>
    </div>
</template>

<script>
    import {getCategoryAndType, getTV, getHitokoto} from "../../api/television";

    export default {
        name: "Animation",
        data() {
            return {
                queryInfo: {
                    categoryId: 1,
                    pageNum: 1,
                    pageSize: 10,
                    type: null,
                    watch: 0
                },
                typeList: [],
                tvList: [],
                categoryList: [],
                total: 0,
                hitokoto: []
            }
        },
        computed: {
            tvCate() {
                return this.categoryList.filter(item => {
                    return item.id === this.queryInfo.categoryId
                })
            }
        },
        created() {
            this.getCategoryAndType()
            this.getHitokoto()
            this.getData()
        },
        methods: {
            getHitokoto() {
                getHitokoto().then(res => {
                    this.hitokoto = res
                })
            },
            getCategoryAndType() {
                getCategoryAndType().then(res => {
                    this.categoryList = res.data.cate
                    this.typeList = res.data.types
                })
            },
            getData() {
                getTV(this.queryInfo).then(res => {
                    this.tvList = res.data.list
                    this.total = res.data.total
                })
            },
            //监听 pageSize 改变事件
            handleSizeChange(newSize) {
                this.queryInfo.pageSize = newSize
                this.getData()
            },
            //监听页码改变的事件
            handleCurrentChange(newPage) {
                this.queryInfo.pageNum = newPage
                this.getData()
            },
            reset() {
                this.queryInfo.watch = 0
                this.queryInfo.type = null
                this.getData()
            },
            setCate(id) {
                this.queryInfo.categoryId = id
                this.reset()
            },
            setType(id) {
                this.queryInfo.type = id
                this.getData()
            },

        },

    }
</script>

<style lang="less" scoped>
    .animation {
        position: relative;
        background-image: url("../../assets/bg1.png");
        background-size: 500px;
        background-repeat: no-repeat;
        background-position: left top;
    }

    .wrap {
        margin-left: 15%;
        overflow: hidden;
        text-align: center;
        min-height: 100vh;
        background-image: url("../../assets/bg.png");
        background-repeat: no-repeat;
        background-size: 338px 1089px;
        background-position: right top;
    }

    .hitokoto {
        position: absolute;
        margin-top: 5%;
        width: 30%;
        padding-left: 5%;
        color: #23aaff;
    }

    .hitokoto-from {
        position: absolute;
        right: 0;
        margin-top: 2em;
    }

    .sidebar {
        position: fixed;
        z-index: 999;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        .sidebar-item {
            position: relative;
            font-weight: 300;
            margin: 0 0 20px 0;
            padding: 0 0 0 50px;
            cursor: pointer;
            font-size: 15px;
            transition: all 0.25s linear;

            &:hover {
                color: #23aaff;

            }
        }
    }


    #main {
        list-style: none;
        text-align: center;
        margin: 0 0 55px 0;


        .type_list {
            display: inline-block;
            margin: 15px 3px 6px 3px;

            .type_li {
                font-size: .8em;
                line-height: 1em;
                background-color: #000;
                color: #fff;
                cursor: pointer;
                letter-spacing: 0.05em;
                padding: 10px 14px;

                &:hover {
                    background-color: #23aaff;
                    transition: all 0.25s linear;
                }
            }
        }

        .content {
            width: 100%;
            margin-top: 3em;

            .content_box {
                width: 15%;
                margin-right: 3%;
                padding-bottom: 10%;
                display: inline-block;
                vertical-align: top;
                letter-spacing: normal;

                .content_box_title {
                    padding: 4px 0;
                    text-align: center;
                    font-size: 2em;
                    color: #454552;
                    word-wrap: break-word;
                    font-family: "Times New Roman", Times, serif;
                    line-height: 1.2em;

                    &:hover {
                        color: #00a2d4;
                        transition: all 0.25s linear;
                    }
                }

                .content_box_img {
                    border-radius: 20px;
                    width: 100%;
                    height: 16em;
                    margin-right: 5px;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .line {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #23aaff;
        width: 5px;
        height: 100%;

        &:after {
            content: " ";
            clear: both;
            display: block;
            height: 0px;
            visibility: hidden;
        }
    }

    .outside {
        opacity: .1;
    }

    .top_wrapper {
        background: #606c88; /* fallback for old browsers */
        background: -webkit-linear-gradient(to left, #3f4c6b, #606c88); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, #3f4c6b, #606c88); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .top_title_box {
        text-align: center;
    }

    .top_title {
        position: relative;
        text-align: center;
        color: #b1b1b1;
        margin: 8em 0 50px 0;
        display: inline-block;
        padding: 0 130px;

        .title-cate {
            font-size: 1.2em;
            cursor: pointer;

            &:hover {
                color: #000000;
                transition: all 0.25s linear;
            }
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            display: inline-block;
            width: 80px;
            height: 1px;
            color: #000000;
            background-color: #000000;
        }
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        #main {
            .content {
                .content_box {
                    width: 30% !important;
                }
            }
        }
    }

</style>
