import axios from '../plugin/axios'

export function getTV(queryInfo) {
    return axios({
        url: 'television/get',
        method: 'GET',
        params: {
            ...queryInfo
        }
    })
}

export function getCategoryAndType() {
    return axios({
        url: 'get/categoryAndType',
        method: 'GET'
    })
}

export function getHitokoto() {
    return axios({
        url: 'https://v1.hitokoto.cn',
        method: 'GET'
    })
}

export function getTVById(id) {
    return axios({
        url: 'television/getTVById',
        method: 'GET',
        params: {
            id
        }
    })
}